/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Controller } from '@hotwired/stimulus';
import type Player from 'video.js/dist/types/player';
import type PlayerPageController from './player_page_controller';

export default class PlayerVideoController extends Controller<HTMLElement> {
  static targets = ['video', 'frame'];
  static values = { expiration: Number };

  declare videoTarget: HTMLVideoElement;
  declare frameTarget: HTMLElement;
  declare expirationValue: number;

  player?: Player;

  get pageController(): PlayerPageController {
    return this.application.getControllerForElementAndIdentifier(
      this.element.closest('[data-controller="player-page"]')!,
      'player-page',
    ) as PlayerPageController;
  }

  async connect() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [videojs] = await Promise.all([
      import('video.js').then((res) => res.default),
      //@ts-ignore
      import('video.js/dist/video-js.css'),
    ]);

    const player = videojs(this.videoTarget, {
      restoreEl: true,
    });
    this.player = player;

    this.player.on('ready', () => {
      this.pageController.dispatch('starlight:player:ready', { prefix: '' });
    });

    this.player.on('timeupdate', () => {
      this.pageController.dispatch('starlight:player:timeupdate', { prefix: '' });
    });

    this.player.on('seeked', () => {
      this.pageController.dispatch('starlight:player:seeked', { prefix: '' });
    });

    this.player.on('error', () => {
      const expiration = (this.expirationValue - 10) * 1000;
      if (Date.now() > expiration) {
        // @ts-ignore
        this.element.closest('turbo-frame')!.src = location.href;
      }
    });
  }

  async getPlayer() {
    if (!this.player) {
      await new Promise((resolve) => {
        this.element.addEventListener('starlight:player:ready', resolve, { once: true });
      });
    }

    return this.player!;
  }

  disconnect(): void {
    this.player?.dispose();
  }
}
