/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Controller } from '@hotwired/stimulus';
import PlayerVideoController from './player_video_controller';
import PlayerTimelineController from './player_timeline_controller';
import PlayerTimelineItemsController from './player_timeline_items_controller';
import PlayerCreateEditClipController from './player_create_edit_clip_controller';
import { request } from '../scripts/request';

export default class PlayerPageController extends Controller<HTMLElement> {
  static values = {
    videoId: String,
    isAdmin: Boolean,
    clipDownloadUrl: String,
    clipDeleteUrl: String,
  };
  static targets = [
    'video',
    'timeline',
    'allClipsFrame',
    'timelineFrame',
    'timeline',
    'timelineItems',
    'createEditClipForm',
    'myClipsFrame',
    'mobileClipsAllClips',
    'mobileClipsMyClips',
    'allClips',
    'myClips',
    'columnButton',
    'mobileClipsMyClipsButton',
  ];

  declare videoIdValue: string;
  declare isAdminValue: boolean;
  declare clipDownloadUrlValue: string;
  declare clipDeleteUrlValue: string;
  declare videoTarget: HTMLVideoElement;
  declare allClipsFrameTarget: HTMLElement;
  declare timelineTarget: HTMLElement;
  declare timelineFrameTarget: HTMLElement;
  declare timelineItemsTarget: HTMLElement;
  declare createEditClipFormTarget: HTMLFormElement;
  declare hasCreateEditClipFormTarget: boolean;
  declare myClipsFrameTarget: HTMLElement;
  declare mobileClipsAllClipsTarget: HTMLElement;
  declare mobileClipsMyClipsTarget: HTMLElement;
  declare allClipsTarget: HTMLElement;
  declare hasMyClipsTarget: boolean;
  declare myClipsTarget: HTMLElement;
  declare columnButtonTargets: HTMLElement[];
  declare mobileClipsMyClipsButtonTarget: HTMLElement;

  activeClipId: string = '';

  allClipsParent?: HTMLElement;
  myClipsParent?: HTMLElement;
  myClipsRefreshes = 0;

  get isAdmin() {
    return this.isAdminValue;
  }

  get videoController(): PlayerVideoController {
    return this.application.getControllerForElementAndIdentifier(
      this.videoTarget,
      'player-video',
    ) as PlayerVideoController;
  }

  get timelineController(): PlayerTimelineController {
    return this.application.getControllerForElementAndIdentifier(
      this.timelineTarget,
      'player-timeline',
    ) as PlayerTimelineController;
  }

  get timelineItemsController(): PlayerTimelineItemsController {
    return this.application.getControllerForElementAndIdentifier(
      this.timelineItemsTarget,
      'player-timeline-items',
    ) as PlayerTimelineItemsController;
  }

  get createEditClipFormController(): PlayerCreateEditClipController | undefined {
    if (!this.hasCreateEditClipFormTarget) return;
    return this.application.getControllerForElementAndIdentifier(
      this.createEditClipFormTarget,
      'player-create-edit-clip',
    ) as PlayerCreateEditClipController;
  }

  aboveMedium = window.matchMedia('(min-width: 46em)');

  connect(): void {
    this.element.addEventListener('click', this.globalClickHandler);
    this.element.addEventListener('starlight:player:ready', this.playerReadyHandler);
    this.aboveMedium.addEventListener('change', this.breakpointChangeHandler);
    this.breakpointChangeHandler();
  }

  disconnect(): void {
    this.element.removeEventListener('click', this.globalClickHandler);
    this.element.removeEventListener('starlight:player:ready', this.playerReadyHandler);
    this.aboveMedium.removeEventListener('change', this.breakpointChangeHandler);
  }

  refreshClips() {
    try {
      // @ts-ignore
      this.timelineFrameTarget.src = location.pathname;
      // @ts-ignore
      this.allClipsFrameTarget.src = location.pathname;
    } catch (error) {
      console.warn('Failed to reload clips frame', error);
    }
  }

  deactivateChips() {
    const chips = this.element.querySelectorAll<HTMLElement>('.js-player-clip-chip-root');
    const timelineItems = this.timelineController.elementsIndex
      .filter((item) => item.type === 'item')
      .map((item) => item.element.firstElementChild as HTMLElement);

    new Set([...chips, ...timelineItems]).forEach((chip) => {
      delete chip.dataset.active;
      delete chip.dataset.controller;
    });

    this.activeClipId = '';
  }

  globalClickHandler = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (this.isAdmin && target.closest('[data-controller="player-create-edit-clip"]')) {
      return;
    }

    const button = target.closest('button');
    if (!button) {
      this.deactivateChips();
      this.createEditClipFormController?.resetForm();
      return;
    }

    const action = button.dataset.playerAction;
    if (!action) return;

    const chipRoot = button.closest('.js-player-clip-chip-root') as HTMLElement;
    if (!chipRoot) return;

    const id = chipRoot.dataset.id as string;
    const start = parseFloat(chipRoot.dataset.start!);

    if (action === 'activate-and-edit' || action === 'activate') {
      this.deactivateChips();

      this.activeClipId = id;

      const chips = this.element.querySelectorAll<HTMLElement>(
        `.js-player-clip-chip-root[data-id="${id}"]`,
      );
      const timelineItems = this.timelineController.elementsIndex
        .filter((item) => item.type === 'item')
        .map((item) => item.element.firstElementChild as HTMLElement)
        .filter((item) => item.dataset.id === id);

      new Set([...chips, ...timelineItems]).forEach((chip) => {
        chip.dataset.active = '';

        if (action === 'activate-and-edit') {
          const isTimelineChip = timelineItems.includes(chip);

          if (isTimelineChip) {
            chip.dataset.controller = 'player-timeline-clip-chip-admin';
          }
        }
      });

      if (action === 'activate-and-edit') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
        this.createEditClipFormController?.editClip(chipRoot.dataset as any);
      }

      this.videoController.player?.currentTime(start);

      const isTimelineChip = !!chipRoot.closest('[data-controller="player-timeline"]');

      if (isTimelineChip) {
        const box = chipRoot.getBoundingClientRect();

        if (box.width < 100) {
          void this.timelineController.zoomToChip(chipRoot);
        }
      }
    } else if (action === 'add') {
      this.addClip(id).catch((error: Error) => {
        alert(`Failed to add clip: ${error.message}`);
      });
    } else if (action === 'download') {
      this.downloadClip(id).catch((error: Error) => {
        alert(`Failed to download clip: ${error.message}`);
      });
    } else if (action === 'delete') {
      this.deleteClip(id).catch((error: Error) => {
        alert(`Failed to delete clip: ${error.message}`);
      });
    }
  };

  breakpointChangeHandler = () => {
    const { matches } = this.aboveMedium;
    const myClipsParent = matches ? this.myClipsParent : this.mobileClipsMyClipsTarget;
    const allClipsParent = matches ? this.allClipsParent : this.mobileClipsAllClipsTarget;

    if (this.hasMyClipsTarget) {
      if (myClipsParent) {
        if (!this.myClipsParent) {
          this.myClipsParent = this.myClipsTarget.parentElement!;
        }

        if (myClipsParent !== this.myClipsTarget.parentElement) {
          myClipsParent.appendChild(this.myClipsTarget);
        }
      }
    }

    if (allClipsParent) {
      if (!this.allClipsParent) {
        this.allClipsParent = this.allClipsTarget.parentElement!;
      }

      if (allClipsParent !== this.allClipsTarget.parentElement) {
        allClipsParent.appendChild(this.allClipsTarget);
      }
    }
  };

  #loadedClipIdFromUrl = false;
  playerReadyHandler = () => {
    if (this.#loadedClipIdFromUrl) return;
    this.#loadedClipIdFromUrl = true;

    const url = new URL(location.href);
    const clipId = url.searchParams.get('clip_id');
    if (!clipId) return;
    const clipIdNumber = parseInt(clipId, 10);
    if (isNaN(clipIdNumber)) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    (
      this.element.querySelector(
        `.js-player-clip-chip-root[data-id="${clipIdNumber}"] button`,
      ) as HTMLButtonElement
    )?.click();
  };

  async addDownloadRequest(id: string) {
    type Response = {
      status: string;
      download_url?: string;
    };

    return await request<Response>(
      this.clipDownloadUrlValue.replace('VIDEO_ID', this.videoIdValue).replace('CLIP_ID', id),
      { method: 'POST' },
      { json: true },
    );
  }

  async addClip(id: string) {
    await this.addDownloadRequest(id);

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.myClipsFrameTarget.reload();

    // code below may be used if we'd like to open the my clips tab after adding a clip

    // const buttonDesktop = this.columnButtonTargets.find(
    //   (b) => b.ariaExpanded === 'false' && b.closest('[data-player-column-id-value="my-clips"]'),
    // );

    // const buttonMobile =
    //   this.mobileClipsMyClipsButtonTarget.ariaSelected === 'false' &&
    //   this.mobileClipsMyClipsButtonTarget;

    // if (buttonDesktop) buttonDesktop.click();
    // if (buttonMobile) buttonMobile.click();
  }

  async downloadClip(id: string) {
    const res = await this.addDownloadRequest(id);

    if (res.download_url) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      window.location = res.download_url;
    } else {
      await this.addClip(id);
    }
  }

  async deleteClip(id: string) {
    await request(
      this.clipDeleteUrlValue.replace('VIDEO_ID', this.videoIdValue).replace('CLIP_ID', id),
      { method: 'DELETE' },
    );

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.myClipsFrameTarget.reload();
  }
}
