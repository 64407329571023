import { Controller } from '@hotwired/stimulus';
import { captureException } from '@sentry/browser';

export default class extends Controller {
  static values = {
    stripePublishableKey: String,
    intentClientSecret: String,
    returnUrl: String,
    fontRegular: String,
  };
  static targets = ['paymentElementContainer', 'errorMessage'];

  async connect() {
    this.element.addEventListener('submit', this.handleFormSubmit);

    const stripe = await import('@stripe/stripe-js').then((module) =>
      module.loadStripe(this.stripePublishableKeyValue),
    );
    this.stripe = stripe;

    const elements = stripe.elements({
      clientSecret: this.intentClientSecretValue,
      locale: 'en',

      fonts: [
        {
          family: 'Roboto Condensed',
          weight: '400',
          src: `url(${this.fontRegularValue})`,
        },
      ],

      appearance: {
        theme: 'night',

        variables: {
          fontFamily: 'Roboto Condensed, sans-serif',
          colorPrimary: '#fff',
          colorBackground: '#000',
          colorText: '#fff',
          colorDanger: '#bc4e47',
          spacingUnit: '6px',
          borderRadius: '12px',
          gridColumnSpacing: '24px',
          gridRowSpacing: '24px',
        },

        rules: {
          '.Tab, .Input, .Tab:hover, .Input:hover, .Tab:focus, .Input:focus': {
            borderColor: '#ccc',
          },

          '.Input:hover': {
            backgroundColor: '#333',
          },

          '.Input:hover:focus': {
            backgroundColor: '#000',
          },

          '.Input:focus': {
            boxShadow: 'none',
            outline: '4px solid rgba(255,255,255,.2)',
          },

          '.Input::placeholder': {
            color: '#808080',
          },
        },
      },
    });

    this.elements = elements;
    this.paymentElement = elements.create('payment');
    const paymentElement = this.paymentElement;
    paymentElement.mount(this.paymentElementContainerTarget);
    paymentElement.on('loaderstart', () => {
      this.element.dataset.loading = null;
    });
    paymentElement.on('loaderror', (e) => {
      alert(e.error.message);
      captureException(e);
    });
  }

  disconnect() {
    this.element.removeEventListener('submit', this.handleFormSubmit);
    this.paymentElement?.destroy();
  }

  handleFormSubmit = async (event) => {
    event.preventDefault();

    this.errorMessageTarget.textContent = '';

    const buttons = Array.from(this.element.querySelectorAll('button[type="submit"]'));

    try {
      buttons.forEach((button) => {
        button.disabled = true;
      });

      this.element.setAttribute('aria-busy', 'true');

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: this.returnUrlValue,
        },
      });

      if (error && error.type !== 'validation_error') {
        this.errorMessageTarget.textContent = error.message;
        captureException(error, { level: 'info' });
      }
    } catch (error) {
      captureException(error);
      this.errorMessageTarget.textContent = error.message;
    } finally {
      buttons.forEach((button) => {
        button.disabled = false;
      });

      this.element.removeAttribute('aria-busy');
    }
  };
}
