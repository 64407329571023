import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['mobileMenuButton'];

  mobileMenuClickHandler() {
    const newExpanded = !(this.mobileMenuButtonTarget.getAttribute('aria-expanded') === 'true');

    this.mobileMenuButtonTarget.setAttribute('aria-expanded', newExpanded);
    document.documentElement.style.overflow = newExpanded ? 'hidden' : '';
  }

  disconnect() {
    const isExpanded = this.mobileMenuButtonTarget.getAttribute('aria-expanded') === 'true';

    if (isExpanded) {
      document.documentElement.style.overflow = '';
    }
  }
}
