/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Controller } from '@hotwired/stimulus';
import PlayerPageController from './player_page_controller';

export default class extends Controller {
  #timeout = 0;

  get pageController(): PlayerPageController {
    return this.application.getControllerForElementAndIdentifier(
      this.element.closest('[data-controller="player-page"]')!,
      'player-page',
    ) as PlayerPageController;
  }

  connect() {
    const notReady = this.element.querySelector('.js-player-clip-chip-root[data-ready="false"]');

    if (notReady && this.pageController.myClipsRefreshes < 200) {
      this.scheduleRefresh();
    }

    this.updateChips();
  }

  disconnect() {
    if (this.#timeout) {
      clearTimeout(this.#timeout);
    }
  }

  scheduleRefresh() {
    if (this.#timeout) {
      clearTimeout(this.#timeout);
    }

    this.#timeout = setTimeout(() => {
      if (document.hidden) {
        this.scheduleRefresh();
        return;
      }

      this.pageController.myClipsRefreshes += 1;

      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.element.closest('turbo-frame')!.reload();
    }, 10000);
  }

  updateChips() {
    const chips = Array.from(
      this.element.querySelectorAll<HTMLElement>('.js-player-clip-chip-root'),
    );

    const root = this.pageController.element;

    root
      .querySelectorAll<HTMLElement>(`.js-player-clip-chip-root[data-added="true"]`)
      .forEach((rootChip) => {
        if (chips.includes(rootChip)) return;

        rootChip.dataset.added = 'false';
      });

    chips.forEach((chip) => {
      const { id, ready, added } = chip.dataset;

      root
        .querySelectorAll<HTMLElement>(`.js-player-clip-chip-root[data-id="${id}"]`)
        .forEach((rootChip) => {
          rootChip.dataset.ready = ready;
          rootChip.dataset.added = added;
        });
    });
  }
}
