/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Controller } from '@hotwired/stimulus';
import PlayerPageController from './player_page_controller';
import { getRemRatio, rafPromise } from '../scripts/utils';

type ItemInfo = {
  element: HTMLElement;
  start: number;
  duration: number;
  end: number;
  row: number;
};

type ElementInfo = {
  element: HTMLElement;
  start: number;
  end: number;
  markerDurationTypes?: number[];
  type: 'item' | 'marker';
};

export default class PlayerTimelineController extends Controller<HTMLElement> {
  static targets = [
    'items',
    'item',
    'markers',
    'marker',
    'scrollable',
    'itemsWrapper',
    'top',
    'topHover',
  ];
  static values = { duration: Number, markers: Array };

  declare scrollableTarget: HTMLElement;
  declare itemsTarget: HTMLElement;
  declare itemTargets: HTMLElement[];
  declare markerTargets: HTMLElement[];
  declare markersTarget: HTMLElement;
  declare itemsWrapperTarget: HTMLElement;
  declare topTarget: HTMLElement;
  declare topHoverTarget: HTMLElement;

  declare durationValue: number;
  declare markersValue: number[];

  itemList: ItemInfo[] = [];
  moveStartMousePosition = 0;
  moveStartScrollLeft = 0;
  moveStartTime = 0;
  elementsIndex: ElementInfo[] = [];
  grabbing = false;
  processedItemsInThisFrame = false;
  pps = 0.0;
  expectedClick = false;
  disconnected = false;
  resizeDebounceTimeout = 0;
  resizeObserver?: ResizeObserver;

  get duration() {
    return this.durationValue;
  }

  get visibleStart() {
    const remRatio = getRemRatio();
    const scrollLeft = this.scrollableTarget.scrollLeft;
    return scrollLeft / (this.pps * remRatio);
  }

  get visibleEnd() {
    const remRatio = getRemRatio();
    const clientWidth = this.element.clientWidth;
    return this.visibleStart + clientWidth / (this.pps * remRatio);
  }

  get pageController(): PlayerPageController {
    return this.application.getControllerForElementAndIdentifier(
      this.element.closest('[data-controller="player-page"]')!,
      'player-page',
    ) as PlayerPageController;
  }

  connect() {
    this.disconnected = false;
    this.element.addEventListener('wheel', this.handleWheel);
    this.element.addEventListener('pointerdown', this.handlePointerDown);
    this.element.addEventListener('click', this.handleClick);
    this.scrollableTarget.addEventListener('scroll', this.handleScroll);
    this.topTarget.addEventListener('mouseenter', this.handleTopMouseEnter);
    this.topTarget.addEventListener('mousemove', this.handleTopMouseMove);
    this.topTarget.addEventListener('mouseleave', this.handleTopMouseLeave);
    this.topTarget.addEventListener('click', this.handleTopClick);
    this.element.style.cursor = 'grab';

    this.element.hidden = false;
    this.pps = parseFloat(this.element.style.getPropertyValue('--c-player-timeline-pps'));
    this.processScaling(0);

    // @ts-ignore
    if (import.meta.hot) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      import.meta.hot.accept(() => {
        const controller = this.application.getControllerForElementAndIdentifier(
          this.element,
          'player-timeline',
        ) as PlayerTimelineController | null;
        if (controller) {
          controller.indexAndProcess();
        }
      });
    }

    this.resizeObserver = new ResizeObserver(() => {
      if (this.resizeDebounceTimeout) {
        clearTimeout(this.resizeDebounceTimeout);
      }

      this.resizeDebounceTimeout = setTimeout(() => {
        if (this.disconnected) return;

        this.hideInvisibleElements();
      }, 100);
    });
    this.resizeObserver.observe(this.element);
  }

  disconnect(): void {
    this.disconnected = true;
    this.element.removeEventListener('wheel', this.handleWheel);
    this.element.removeEventListener('pointerdown', this.handlePointerDown);
    this.element.removeEventListener('click', this.handleClick);
    this.scrollableTarget.removeEventListener('scroll', this.handleScroll);
    this.topTarget.removeEventListener('mouseenter', this.handleTopMouseEnter);
    this.topTarget.removeEventListener('mousemove', this.handleTopMouseMove);
    this.topTarget.removeEventListener('mouseleave', this.handleTopMouseLeave);
    this.topTarget.removeEventListener('click', this.handleTopClick);
    this.restoreHiddenElements();
    this.resizeObserver?.disconnect();
  }

  indexAndProcess() {
    this.indexElements();
    this.processTimelineItems();
    this.hideInvisibleElements();
  }

  restoreHiddenElements({ markers, clips } = { markers: true, clips: true }) {
    // for hot reload we restore removed elements
    this.elementsIndex.forEach((elementInfo) => {
      if (elementInfo.type === 'marker') {
        if (markers) {
          this.markersTarget.appendChild(elementInfo.element);
        }
      } else if (elementInfo.type === 'item') {
        if (clips) {
          this.itemsTarget.appendChild(elementInfo.element);
        }
      }
    });
  }

  indexElements() {
    this.elementsIndex = [];
    // to optimize performance we remove all elements from the DOM and store them in an array
    // then we append them back to the DOM when they are needed
    this.markerTargets.forEach((element) => {
      const start = parseInt(element.style.getPropertyValue('--c-player-timeline-marker-start'));
      const durationTypes = element.dataset.durationType!.split(' ').map((type) => parseInt(type));
      const end = start + Math.max(...durationTypes);

      this.elementsIndex.push({
        element,
        start,
        end,
        markerDurationTypes: durationTypes,
        type: 'marker',
      });
      element.remove();
    });

    this.itemTargets.forEach((element) => {
      const start = parseInt(element.dataset.start!);
      const duration = parseInt(element.dataset.duration!);
      const end = start + duration;

      this.elementsIndex.push({ element, start, end, type: 'item' });
      element.remove();
    });
  }

  hideInvisibleElements() {
    if (this.processedItemsInThisFrame) return;
    this.processedItemsInThisFrame = true;

    requestAnimationFrame(() => {
      this.processedItemsInThisFrame = false;
    });

    const { visibleStart, visibleEnd } = this;
    const visibleMarker = this.markersValue.find((marker) => marker * this.pps > 60);

    this.elementsIndex.forEach((elementInfo) => {
      if (elementInfo.end < visibleStart || elementInfo.start > visibleEnd) {
        elementInfo.element.remove();
      } else {
        if (elementInfo.type === 'marker') {
          const visible = elementInfo.markerDurationTypes!.includes(visibleMarker!);
          if (visible) {
            this.markersTarget.appendChild(elementInfo.element);
          } else {
            elementInfo.element.remove();
          }
        } else if (elementInfo.type === 'item') {
          this.itemsTarget.appendChild(elementInfo.element);
        }
      }
    });
  }

  processTimelineItems() {
    const visibleItems = new Set<ItemInfo>();

    this.itemList = this.elementsIndex
      .filter((elementInfo) => elementInfo.type === 'item')
      .map(({ element }) => {
        const start = parseFloat(element.dataset.start!);
        const duration = parseFloat(element.dataset.duration!);
        const end = start + duration;

        return { element, start, duration, end, row: -1 };
      })
      .sort((a, b) => a.start - b.start)
      .map((item) => {
        visibleItems.forEach((visibleItem) => {
          if (visibleItem.end <= item.start) {
            visibleItems.delete(visibleItem);
          }
        });

        const usedRows = Array.from(visibleItems).map((visibleItem) => visibleItem.row);

        item.row = new Array(usedRows.length + 1)
          .fill(0)
          .findIndex((_, i) => !usedRows.includes(i));

        item.element.style.setProperty('--c-player-timeline-item-row', item.row.toString());

        visibleItems.add(item);

        return item;
      });

    const maxRow = Math.max(...this.itemList.map((item) => item.row));

    this.element.style.setProperty('--c-player-timeline-rows', (maxRow + 1).toString());
  }

  processScaling(dir: number, value: number = 0): number | undefined {
    const currentPps = this.pps;
    let newPps = currentPps + dir * currentPps * (value / 1000);
    const clientWidth = this.element.clientWidth;
    // 10 to account for current time indicator going out of view
    const minPps = (clientWidth - 10) / this.duration;

    newPps = Math.max(minPps, Math.min(10, newPps));

    if (newPps === currentPps) return;
    this.element.style.setProperty('--c-player-timeline-pps', newPps.toString());
    this.pps = newPps;

    const ppsChange = (newPps - currentPps) / currentPps;

    return ppsChange;
  }

  scrollLeft = 0;
  adjustTimelinePositionAfterScaling(ppsChange: number, pageX: number) {
    const left = this.element.getBoundingClientRect().left;
    const mousePosition = pageX - left;
    const newMousePosition = mousePosition * (1 + ppsChange);
    const mousePositionChange = newMousePosition - mousePosition;
    const scrollable = this.scrollableTarget;
    let scrollLeft = this.scrollLeft;
    if (Math.abs(scrollLeft - scrollable.scrollLeft) > 2) {
      scrollLeft = scrollable.scrollLeft;
      this.scrollLeft = scrollable.scrollLeft;
    }
    this.scrollLeft += scrollLeft * ppsChange + mousePositionChange;
    scrollable.style.overflowX = 'hidden';
    scrollable.scrollLeft = this.scrollLeft;
    scrollable.style.overflowX = '';
  }

  handleWheel = (event: WheelEvent) => {
    if (Math.abs(event.deltaY) === 0) return;

    this.processZoom(event.deltaY, event.pageX);
  };

  processZoom(deltaY: number, pageX: number) {
    const dir = Math.sign(deltaY) * -1; // zoom in when negative, zoom out when positive
    const value = Math.abs(deltaY);

    const ppsChange = this.processScaling(dir, value);

    if (ppsChange === undefined) return;

    this.adjustTimelinePositionAfterScaling(ppsChange, pageX);
    this.hideInvisibleElements();
  }

  async zoomToChip(chip: HTMLElement) {
    const start = performance.now();
    let frameStart = start;
    const remRatio = getRemRatio();
    const minWidth = 100 * remRatio;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      await rafPromise();

      const now = performance.now();
      if (now - start > 2000) return; // arbitrary limit for potential infinite loop

      if (!this.element.contains(chip)) return;

      const box = chip.getBoundingClientRect();
      if (!box.width || box.width >= minWidth) return;

      const pageX = box.left + box.width / 2;
      const diff = now - frameStart;

      this.processZoom(diff * -1 * 10, pageX);

      frameStart = now;
    }
  }

  handlePointerDown = (event: PointerEvent) => {
    const target = event.target as HTMLElement;
    if (
      // in admin dragging the chip moves it. not the timeline
      target.closest('[data-controller="player-timeline-clip-chip-admin"]') ||
      this.topTarget.contains(target)
    ) {
      return;
    }

    this.element.addEventListener('pointermove', this.handlePointerMove);
    this.element.addEventListener('pointerup', this.handlePointerUp);
    this.element.addEventListener('pointercancel', this.handlePointerUp);
    this.moveStartMousePosition = event.pageX;
    this.moveStartScrollLeft = this.scrollableTarget.scrollLeft;
    this.moveStartTime = event.timeStamp;
    this.element.style.cursor = 'grabbing';
    this.grabbing = true;
  };

  handlePointerMove = (event: PointerEvent) => {
    // console.log('pointer move', event);
    this.scrollableTarget.scrollLeft =
      this.moveStartScrollLeft + this.moveStartMousePosition - event.pageX;

    if (event.timeStamp - this.moveStartTime > 200) {
      this.element.setPointerCapture(event.pointerId);
    }

    event.preventDefault();
  };

  handlePointerUp = (event: PointerEvent) => {
    this.element.style.cursor = 'grab';
    this.grabbing = false;
    this.element.removeEventListener('pointermove', this.handlePointerMove);
    this.element.removeEventListener('pointerup', this.handlePointerUp);
    this.element.removeEventListener('pointercancel', this.handlePointerUp);

    event.preventDefault();

    if (event.timeStamp - this.moveStartTime < 200 || event.type !== 'pointerup') {
      this.expectedClick = true;

      setTimeout(() => {
        this.expectedClick = false;
      });
    }
  };

  handleClick = (event: MouseEvent) => {
    // prevent dragging on timeline to be recognized as a click
    if (!this.expectedClick) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  handleScroll = () => {
    this.hideInvisibleElements();
  };

  scrollToTime(time: number) {
    const clientWidth = this.element.clientWidth;
    const scrollLeft = time * this.pps - clientWidth / 2;
    this.scrollableTarget.scrollLeft = scrollLeft;
  }

  handleTopMouseEnter = () => {
    this.topHoverTarget.style.display = 'block';
  };

  handleTopMouseLeave = () => {
    this.topHoverTarget.style.display = '';
  };

  handleTopMouseMove = (event: MouseEvent) => {
    const { left } = this.topTarget.getBoundingClientRect();
    this.topHoverTarget.style.left = `${event.pageX - left}px`;
  };

  handleTopClick = (event: MouseEvent) => {
    const { left } = this.topTarget.getBoundingClientRect();
    const time = (event.pageX - left) / this.pps / getRemRatio();
    this.pageController.videoController.player?.currentTime(time);
  };
}
