/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Controller } from '@hotwired/stimulus';
import PlayerPageController from './player_page_controller';
import { request } from '../scripts/request';
import type interact from '../scripts/interact';
import { getRemRatio } from '../scripts/utils';

export default class PlayerTimelineClipChipAdminController extends Controller<HTMLElement> {
  get pageController(): PlayerPageController {
    return this.application.getControllerForElementAndIdentifier(
      this.element.closest('[data-controller="player-page"]')!,
      'player-page',
    ) as PlayerPageController;
  }

  #interact?: typeof interact;
  #setupComplete = false;
  #disconnectTimeout = 0;

  async connect() {
    if (this.#disconnectTimeout) {
      clearTimeout(this.#disconnectTimeout);
      this.#disconnectTimeout = 0;
    }

    if (this.#setupComplete) return;

    const { pageController } = this;
    const interact = await import('../scripts/interact').then((interact) => interact.default);
    this.#interact = interact;

    const interactingStart = (event: Interact.ResizeEvent) => {
      const target = event.target as HTMLElement;
      const parentNode = target.parentNode as HTMLElement;

      parentNode.dataset.interacting = '';
    };

    const interactingEnd = async (event: Interact.ResizeEvent) => {
      const target = event.target as HTMLElement;
      const parentNode = target.parentNode as HTMLElement;

      delete parentNode.dataset.interacting;

      const id = target.dataset.id;

      const left = parentNode.offsetLeft;
      const width = parentNode.offsetWidth;
      const { pps } = pageController.timelineController;

      const clip = {
        start_time: Math.round(left / pps / getRemRatio()),
        end_time: Math.round((left + width) / pps / getRemRatio()),
      };

      try {
        await request(
          `/editor/videos/${this.pageController.videoIdValue}/clips/${id}`,
          {
            method: 'PATCH',
            body: { clip },
          },
          { json: true },
        );
      } catch (error: unknown) {
        const e = error instanceof Error ? error : new Error(String(error));
        alert(`Failed to update clip: ${e.message}`);
      } finally {
        this.pageController.refreshClips();
      }
    };

    if (this.#setupComplete) return;

    interact(this.element)
      .resizable({
        edges: { left: true, right: true },

        modifiers: [
          interact.modifiers.restrictSize({
            // @ts-ignore
            min: { width: 100 * getRemRatio() },
          }),
        ],

        listeners: {
          start: interactingStart,

          move: function (event: Interact.ResizeEvent) {
            const target = event.target as HTMLElement;
            const parentNode = target.parentNode as HTMLElement;

            const containerWidth = pageController.timelineController.itemsWrapperTarget.offsetWidth;
            let width = parentNode.offsetWidth;
            let left = parentNode.offsetLeft;
            const originalLeft = left;
            left += event.deltaRect!.left;
            left = Math.max(0, left);
            const leftDiff = left - originalLeft;

            width += event.deltaRect!.right - leftDiff;
            width = Math.min(containerWidth - left, width);

            parentNode.style.width = `${width}px`;
            parentNode.style.left = `${left}px`;

            const { pps } = pageController.timelineController;
            const { player } = pageController.videoController;
            player?.pause();
            if (event.deltaRect!.left) {
              player?.currentTime(left / pps / getRemRatio());
            } else {
              player?.currentTime((left + width) / pps / getRemRatio());
            }
          },

          end: interactingEnd,
        },
      })
      .draggable({
        listeners: {
          start: interactingStart,

          move(event: Interact.DragEvent) {
            const target = event.target as HTMLElement;
            const parentNode = target.parentNode as HTMLElement;

            let left = parentNode.offsetLeft + event.dx;
            const width = parentNode.offsetWidth;
            const containerWidth = pageController.timelineController.itemsWrapperTarget.offsetWidth;
            left = Math.max(0, left);
            left = Math.min(containerWidth - width, left);

            parentNode.style.left = `${left}px`;

            const { pps } = pageController.timelineController;
            const { player } = pageController.videoController;
            player?.pause();
            player?.currentTime(left / pps / getRemRatio());
          },

          end: interactingEnd,
        },
      });

    this.#setupComplete = true;
  }

  disconnect(): void {
    if (this.#disconnectTimeout) {
      clearTimeout(this.#disconnectTimeout);
    }

    this.#disconnectTimeout = setTimeout(() => {
      this.#interact?.(this.element).unset();
      this.#disconnectTimeout = 0;
      this.#setupComplete = false;
    }, 1000);
  }
}
