import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { fields: Array, action: String };

  connect() {
    this.element.addEventListener('turbo:submit-start', this.onSubmitStart);
    this.element.addEventListener('change', this.onChange);
    this.element.addEventListener('turbo:before-frame-render', this.onBeforeFrameRender);

    this.onChange();
  }

  disconnect() {
    this.element.removeEventListener('turbo:submit-start', this.onSubmitStart);
    this.element.removeEventListener('change', this.onChange);
    this.element.removeEventListener('turbo:before-frame-render', this.onBeforeFrameRender);
  }

  onSubmitStart = (e) => {
    const { formSubmission } = e.detail;
    if (formSubmission.method !== 'get') return;
    if (formSubmission.submitter !== this.submitValidate) return;

    const { searchParams } = formSubmission.fetchRequest.url;

    const allowedKeys = this.fieldsValue;

    Array.from(searchParams.keys()).forEach((key) => {
      if (!allowedKeys.includes(key)) {
        searchParams.delete(key);
      }
    });
  };

  onBeforeFrameRender = (e) => {
    Array.from(e.detail.newFrame.querySelectorAll('input, textarea')).forEach((el) => {
      if (!el.id) return;
      const oldEl = e.target.querySelector(`#${el.id}`);
      if (!oldEl) return;

      if (el.type === 'checkbox' || el.type === 'radio') {
        el.checked = oldEl.checked;
      } else if (el.type === 'file') {
        el.files = oldEl.files;
      } else {
        el.value = oldEl.value;
      }
    });
  };

  onChange = () => {
    const list = this.fieldsValue.map((field) => this.element.elements[field].value);
    const listStr = list.join('::');

    if (this.comparableValues === undefined) {
      this.comparableValues = listStr;
      return;
    } else if (this.comparableValues === listStr) {
      return;
    }

    this.comparableValues = listStr;

    if (!this.submitValidate) {
      const button = document.createElement('button');
      button.type = 'submit';
      button.hidden = true;
      button.formMethod = 'get';
      button.formAction = this.actionValue;
      button.dataset.turboFrame = this.element.querySelector('turbo-frame').id;
      this.element.appendChild(button);
      this.submitValidate = button;
    }

    this.submitValidate.click();
  };
}
