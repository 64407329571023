import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.clickHandler);
  }

  disconnect() {
    this.element.removeEventListener('click', this.clickHandler);
  }

  clickHandler = () => {
    this.element.setAttribute(
      'aria-expanded',
      String(this.element.getAttribute('aria-expanded') === 'false'),
    );
  };
}
