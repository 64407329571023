import { Controller } from '@hotwired/stimulus';

const largeScreen = window.matchMedia('(min-width: 64em');
const xLargeScreen = window.matchMedia('(min-width: 75em');

export default class PlayerColumnController extends Controller {
  static targets = ['inner', 'button'];
  static outlets = ['player-column', 'timeline'];
  static values = { side: String };

  declare innerTarget: HTMLElement;
  declare buttonTarget: HTMLElement;
  declare playerColumnOutlets: PlayerColumnController[];
  declare sideValue: string;

  connect() {
    if (this.sideValue === 'right' && largeScreen.matches && !xLargeScreen.matches) {
      this.hide();
    }
  }

  toggle() {
    this.innerTarget.hidden = !this.innerTarget.hidden;
    this.buttonTarget.setAttribute('aria-expanded', String(!this.innerTarget.hidden));
    if (!this.innerTarget.hidden && largeScreen.matches && !xLargeScreen.matches) {
      this.playerColumnOutlets.forEach((playerColumn) => {
        if (playerColumn !== this) {
          playerColumn.hide();
        }
      });
    }
  }

  hide() {
    if (!this.innerTarget.hidden) {
      this.toggle();
    }
  }

  handleToggleClick() {
    this.toggle();
  }
}
