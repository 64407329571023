import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tabList', 'tab', 'tabPanel'];

  handleTabListKeyDown(e) {
    if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
      const tabs = this.tabTargets;
      const currentIndex = tabs.findIndex((tab) => tab.tabIndex === 0);
      let nextIndex = currentIndex + (e.key === 'ArrowRight' ? 1 : -1);
      if (nextIndex < 0) nextIndex = tabs.length - 1;
      if (nextIndex >= tabs.length) nextIndex = 0;
      tabs[currentIndex].tabIndex = -1;
      tabs[nextIndex].tabIndex = 0;
      tabs[nextIndex].focus();
    }
  }

  handleTabClick(e) {
    const button = e.target.closest('button');
    if (!button) return;
    const tabs = this.tabTargets;
    if (!tabs.includes(button)) return;
    const clickIndex = tabs.indexOf(button);

    tabs.forEach((tab, index) => {
      tab.ariaSelected = index === clickIndex;
    });

    const panels = this.tabPanelTargets;

    panels.forEach((panel, index) => {
      panel.hidden = index !== clickIndex;
    });
  }
}
