export function toPx(value: string | number) {
  if (typeof value === 'number') {
    return value;
  }

  if (value.endsWith('rem')) {
    const val = parseFloat(value);
    const remRatio = getRemRatio();
    return val * remRatio * 10;
  }

  throw new Error(`Unsupported unit: ${value}`);
}

export function rafPromise() {
  return new Promise((resolve) => requestAnimationFrame(resolve));
}

export function getRemRatio() {
  return parseFloat(window.getComputedStyle(document.documentElement).fontSize) / 10;
}
