/* eslint-disable @typescript-eslint/no-misused-promises */

import { Controller } from '@hotwired/stimulus';
import PlayerPageController from './player_page_controller';

type Chip = {
  start: number;
  element: HTMLElement;
};

export default class PlayerMobileTimelineController extends Controller<HTMLElement> {
  #pageController?: PlayerPageController;
  get pageController(): PlayerPageController {
    if (!this.#pageController) {
      this.#pageController = this.application.getControllerForElementAndIdentifier(
        this.element.closest('[data-controller="player-page"]')!,
        'player-page',
      ) as PlayerPageController;
    }

    return this.#pageController;
  }

  #chipsIndex: Chip[] = [];

  connect(): void {
    this.pageController.element.addEventListener(
      'starlight:player:timeupdate',
      this.handleTimeUpdate,
    );

    this.pageController.element.addEventListener('starlight:player:seeked', this.handleTimeUpdate);

    this.#chipsIndex = Array.from(
      this.element.querySelectorAll<HTMLElement>('.js-player-clip-chip-root'),
    )
      .reverse()
      .map((el) => ({
        start: parseFloat(el.dataset.start!),
        element: el,
      }));
  }

  disconnect(): void {
    this.pageController.element.removeEventListener(
      'starlight:player:timeupdate',
      this.handleTimeUpdate,
    );

    this.pageController.element.removeEventListener(
      'starlight:player:seeked',
      this.handleTimeUpdate,
    );
  }

  getPlayer() {
    return this.pageController.videoController.getPlayer();
  }

  #lastMatchedChip?: Chip;
  handleTimeUpdate = async () => {
    const player = await this.getPlayer();
    const currentTime = player.currentTime() ?? 0;

    const matchingChip = this.#chipsIndex.find((chip) => chip.start <= currentTime);

    if (!matchingChip) {
      this.#lastMatchedChip = undefined;
      return;
    }

    if (this.#lastMatchedChip === matchingChip) return;
    this.#lastMatchedChip = matchingChip;
    void this.scrollIntoChip(matchingChip);
  };

  async scrollIntoChip(chip: Chip) {
    const scrollIntoView = await import('smooth-scroll-into-view-if-needed').then(
      (mod) => mod.default,
    );

    if (this.#lastMatchedChip !== chip) return;

    void scrollIntoView(chip.element, {
      block: 'center',
      behavior: 'smooth',
      boundary: this.element,
    });
  }
}
