/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Controller } from '@hotwired/stimulus';
import PlayerPageController from './player_page_controller';

export default class PlayerTimelineItemsController extends Controller<HTMLElement> {
  #pagesController?: PlayerPageController;

  get pageController(): PlayerPageController {
    if (!this.#pagesController) {
      this.#pagesController = this.application.getControllerForElementAndIdentifier(
        this.element.closest('[data-controller="player-page"]')!,
        'player-page',
      ) as PlayerPageController;
    }

    return this.#pagesController;
  }

  connect(): void {
    this.pageController.timelineController.indexAndProcess();

    const { activeClipId: activeClipId, isAdmin } = this.pageController;
    if (activeClipId) {
      const chip = this.element.querySelector(
        `.js-player-clip-chip-root[data-id="${activeClipId}"]`,
      ) as HTMLElement;
      if (chip) {
        chip.dataset.active = '';
        if (isAdmin) {
          chip.dataset.controller = 'player-timeline-clip-chip-admin';
        }
      }
    }
  }

  disconnect(): void {
    // when element is in body we disconnected due to hot reload
    // when element is not in body we disconnected due to page change or frame refresh
    this.pageController?.timelineController?.restoreHiddenElements({
      markers: true,
      clips: document.body.contains(this.element),
    });
  }
}
