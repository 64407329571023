import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'content'];

  connect() {
    const currentOrigin = location.origin + '/';
    this.element.querySelectorAll('.trix-content a').forEach((link) => {
      if (!link.href.startsWith(currentOrigin)) {
        link.target = '_blank';
      }
    });
  }

  clickHandler() {
    this.contentTarget.hidden = !this.contentTarget.hidden;
    this.buttonTarget.setAttribute('aria-expanded', String(!this.contentTarget.hidden));
  }
}
