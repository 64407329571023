/* eslint-disable @typescript-eslint/no-misused-promises */

import { Controller } from '@hotwired/stimulus';
import PlayerPageController from './player_page_controller';

export default class PlayerTimelineCurrentTimeController extends Controller<HTMLElement> {
  #pageController?: PlayerPageController;
  get pageController(): PlayerPageController {
    if (!this.#pageController) {
      this.#pageController = this.application.getControllerForElementAndIdentifier(
        this.element.closest('[data-controller="player-page"]')!,
        'player-page',
      ) as PlayerPageController;
    }

    return this.#pageController;
  }

  connect() {
    void this.setCurrentTime();
    this.pageController.element.addEventListener(
      'starlight:player:timeupdate',
      this.handleTimeUpdate,
    );
    this.pageController.element.addEventListener('starlight:player:seeked', this.handleSeeked);
    this.element.hidden = false;
  }

  disconnect() {
    this.pageController.element.removeEventListener(
      'starlight:player:timeupdate',
      this.handleTimeUpdate,
    );
    this.pageController.element.removeEventListener('starlight:player:seeked', this.handleSeeked);
  }

  getPlayer() {
    return this.pageController.videoController.getPlayer();
  }

  handleTimeUpdate = async () => {
    await this.setCurrentTime();

    const player = await this.getPlayer();

    const { timelineController } = this.pageController;
    if (timelineController.grabbing) return;

    const { visibleStart, visibleEnd } = timelineController;
    const diff = visibleEnd - visibleStart;
    const diff10 = diff / 10;
    const diff80 = diff * 0.8;
    const currentTime = player.currentTime() ?? 0;
    const isCurrentTimeVisible = currentTime >= visibleStart && currentTime <= visibleEnd;

    if (isCurrentTimeVisible && currentTime > visibleEnd - diff10) {
      timelineController.scrollToTime(currentTime + diff80 / 2);
    }
  };

  async setCurrentTime() {
    const player = await this.getPlayer();
    const currentTime = player.currentTime() ?? 0;
    this.element.style.setProperty('--c-player-timeline-current-time', `${currentTime}`);
  }

  handleSeeked = async () => {
    const { timelineController } = this.pageController;
    if (timelineController.grabbing) return;

    const player = await this.getPlayer();

    const { visibleStart, visibleEnd } = timelineController;
    const currentTime = player.currentTime() ?? 0;

    if (currentTime < visibleStart || currentTime > visibleEnd) {
      timelineController.scrollToTime(currentTime);
    }
  };
}
