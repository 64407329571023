import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  groups = [];

  connect() {
    const groups = Array.from(this.element.querySelectorAll('.js-player-all-clips-group'));

    this.groups = groups.map((group) => ({
      element: group,
      clips: Array.from(group.querySelectorAll('.js-player-clips-list-item')).map((clip) => ({
        element: clip,
        player: clip.dataset.player.toLowerCase(),
      })),
    }));
  }

  formSubmit() {
    // noop
  }

  filterByPlayer(e) {
    const value = e.target.value.toLowerCase();
    this.groups.forEach((group) => {
      let hasMatchingPlayer = false;

      group.clips.forEach((clip) => {
        if (clip.player.includes(value)) {
          hasMatchingPlayer = true;
          clip.element.hidden = false;
        } else {
          clip.element.hidden = true;
        }
      });

      group.element.hidden = !hasMatchingPlayer;
    });
  }
}
