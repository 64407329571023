import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['inner'];
  static values = { list: Array };

  currentImage = 0;
  hasHover = false;
  timeout = 0;
  touchStartX = 0;

  connect() {
    this.images = [
      { data: null, element: this.innerTarget.children[0] },
      ...this.listValue.map((data) => ({ data, element: null })),
    ];

    this.element.addEventListener('touchstart', this.handlePointerEnter);
    this.element.addEventListener('touchend', this.handlePointerLeave);
    this.element.addEventListener('touchcancel', this.handlePointerLeave);
    this.element.addEventListener('mouseenter', this.handlePointerEnter);
    this.element.addEventListener('mouseleave', this.handlePointerLeave);
  }

  disconnect() {
    this.element.removeEventListener('touchstart', this.handlePointerEnter);
    this.element.removeEventListener('touchend', this.handlePointerLeave);
    this.element.removeEventListener('touchcancel', this.handlePointerLeave);
    this.element.removeEventListener('mouseenter', this.handlePointerEnter);
    this.element.removeEventListener('mouseleave', this.handlePointerLeave);
  }

  handlePointerEnter = (event) => {
    if (this.hasHover) return;
    this.hasHover = true;
    if (event.type.startsWith('touch')) {
      const { changedTouches } = event;
      if (changedTouches.length === 1) {
        this.touchStartX = changedTouches[0].clientX;
      }
    } else {
      this.loadAndDisplayNextImage();
    }
  };

  handlePointerLeave = (event) => {
    this.hasHover = false;
    if (this.timeout) clearTimeout(this.timeout);

    if (event.type.startsWith('touch')) {
      const { changedTouches } = event;
      if (changedTouches.length === 1) {
        const touchEndX = changedTouches[0].clientX;
        const diff = touchEndX - this.touchStartX;
        if (Math.abs(diff) > 50) {
          event.preventDefault();
          this.currentImage = this.currentImage + Math.sign(diff) * -1;
          if (this.currentImage < 0) this.currentImage = this.images.length - 1;
          if (this.currentImage >= this.images.length) this.currentImage = 0;
          this.loadImage(this.currentImage).then(() => {
            this.innerTarget.children[0].replaceWith(this.images[this.currentImage].element);
          });
        }
      }
    }
  };

  async loadImage(index) {
    const image = this.images[index];

    if (!image.element) {
      const el = document.createElement('img');
      const { data } = image;

      el.src = data[0].url;
      el.alt = '';
      el.srcset = data.map(({ url, width }) => `${url} ${width}w`).join(', ');
      el.sizes = this.images[0].element.sizes;

      image.element = el;
    }

    const { element } = image;

    if (element.complete) return;

    await Promise.race([
      new Promise((resolve) => element.addEventListener('load', resolve, { once: true })),
      new Promise((resolve) => setTimeout(resolve, 1000)),
      new Promise((resolve) => {
        let checks = 0;
        (function check() {
          if (element.complete) {
            resolve();
          } else if (checks++ < 100) {
            setTimeout(check, 10);
          }
        })();
      }),
    ]);
  }

  loadAndDisplayNextImage = async () => {
    if (!this.hasHover) return;

    this.currentImage = (this.currentImage + 1) % this.images.length;

    await this.loadImage(this.currentImage);
    if (!this.hasHover) return;

    // preload next image
    this.loadImage((this.currentImage + 1) % this.images.length);

    const image = this.images[this.currentImage];

    this.innerTarget.children[0].replaceWith(image.element);

    this.timeout = setTimeout(this.loadAndDisplayNextImage, 1000);
  };
}
