import { Controller } from '@hotwired/stimulus';
import loadSwiper from '../scripts/swiper';
import { toPx } from '../scripts/utils';

const aboveSmall = window.matchMedia('(min-width: 30em)');
// TODO: single source for breakpoints?

export default class extends Controller {
  async connect() {
    this.swiperLibrary = await loadSwiper();

    if (!aboveSmall.matches) {
      this.createSwiper();
    }

    aboveSmall.addEventListener('change', this.handleMediaChange);
  }

  createSwiper() {
    if (this.swiper) return;

    const computedStyles = getComputedStyle(this.element);

    const containerMargin = toPx(computedStyles.getPropertyValue('--o-container-margin'));

    this.swiper = new this.swiperLibrary.Swiper(this.element, {
      slidesPerView: 1.5,
      spaceBetween: toPx('2.4rem'),
      slidesOffsetBefore: containerMargin,
      slidesOffsetAfter: containerMargin,
    });
  }

  destroySwiper() {
    if (!this.swiper) return;

    this.swiper.destroy();
    this.swiper = null;
  }

  handleMediaChange = () => {
    if (aboveSmall.matches) {
      this.destroySwiper();
    } else {
      this.createSwiper();
    }
  };

  disconnect() {
    aboveSmall.removeEventListener('change', this.handleMediaChange);
    this.destroySwiper();
  }
}

window.loadSwiper = loadSwiper;
