import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['output'];

  connect() {
    // changing text should update it without reloading page
    this.outputTarget.textContent = 'Hello World7!';
  }

  clickHandler() {
    throw new Error('This is an error');
  }
}
