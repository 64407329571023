/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Controller } from '@hotwired/stimulus';
import PlayerPageController from './player_page_controller';
import Player from 'video.js/dist/types/player';
import { request } from '../scripts/request';

export default class PlayerCreateEditClipController extends Controller<HTMLFormElement> {
  static template: HTMLFormElement;
  static values = { videoId: String, videoDuration: Number };
  static targets = ['resetButton', 'deleteButton'];

  declare videoIdValue: string;
  declare videoDurationValue: number;
  declare resetButtonTarget: HTMLButtonElement;
  declare deleteButtonTarget: HTMLButtonElement;

  /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call */
  get gameActionsFieldset(): HTMLFieldSetElement {
    //@ts-ignore
    return this.element.elements.game_action_id[0].closest('fieldset');
  }

  get gameActionsInputs(): Array<HTMLInputElement> {
    //@ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return Array.from(this.element.elements.game_action_id);
  }

  get gameActionId(): string {
    //@ts-ignore
    return this.element.elements.game_action_id.value as string;
  }

  set gameActionId(value: string) {
    //@ts-ignore
    this.element.elements.game_action_id.value = value;
  }

  get participatingTeamsFieldset(): HTMLFieldSetElement {
    //@ts-ignore
    return this.element.elements.participating_team_id[0].closest('fieldset');
  }

  get participatingTeamsInputs(): Array<HTMLInputElement> {
    //@ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return Array.from(this.element.elements.participating_team_id);
  }

  get participatingTeamId(): string {
    //@ts-ignore
    return this.element.elements.participating_team_id.value as string;
  }

  set participatingTeamId(value: string) {
    //@ts-ignore
    this.element.elements.participating_team_id.value = value;
  }

  get playersFieldset(): HTMLFieldSetElement {
    //@ts-ignore
    return this.element.elements.player_id[0].closest('fieldset');
  }

  get playersInputs(): Array<HTMLInputElement> {
    //@ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return Array.from(this.element.elements.player_id);
  }

  get playerId(): string {
    //@ts-ignore
    return this.element.elements.player_id.value as string;
  }

  set playerId(value: string) {
    //@ts-ignore
    this.element.elements.player_id.value = value;
  }
  /* eslint-enable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call */

  previousGameActionId: string = '';
  previousParticipatingTeamId: string = '';
  previousPlayerId: string = '';
  id: string = '';
  mode: 'create' | 'edit' = 'create';

  get pageController(): PlayerPageController {
    return this.application.getControllerForElementAndIdentifier(
      this.element.closest('[data-controller="player-page"]')!,
      'player-page',
    ) as PlayerPageController;
  }

  get player(): Player {
    return this.pageController.videoController.player!;
  }

  connect(): void {
    if (!PlayerCreateEditClipController.template) {
      PlayerCreateEditClipController.template = this.element.cloneNode(true) as HTMLFormElement;
    }

    this.element.addEventListener('change', this.handleChangeEvent);
    this.render();
  }

  disconnect(): void {
    this.element.removeEventListener('change', this.handleChangeEvent);
  }

  handleChangeEvent = (): void => {
    if (this.participatingTeamId !== this.previousParticipatingTeamId) {
      const checkedPlayer = this.playersInputs.find((input) => input.checked);
      if (checkedPlayer) {
        checkedPlayer.checked = false;
      }
    }

    this.setPreviousValues();

    const isClipReady = Boolean(this.gameActionId && this.participatingTeamId && this.playerId);

    if (isClipReady) {
      const method = this.mode === 'create' ? 'addClip' : 'updateClip';
      this[method]().catch((error: Error) => {
        alert(`Failed to add clip: ${error?.message}`);
      });
      return;
    }

    this.render();
    this.player.pause();
  };

  render() {
    const { gameActionId, participatingTeamId, playerId } = this;

    const { mode } = this;
    this.resetButtonTarget.hidden = !gameActionId && !participatingTeamId && !playerId;
    this.deleteButtonTarget.hidden = mode === 'create';

    const hideInactiveGameActions = Boolean(
      mode === 'create' && gameActionId && (!participatingTeamId || !playerId),
    );
    this.gameActionsInputs.forEach((input) => {
      const label = input.closest('label');
      label!.hidden = hideInactiveGameActions && input.value !== gameActionId;
    });

    this.participatingTeamsFieldset.hidden = Boolean(!gameActionId);

    this.playersFieldset.hidden = !participatingTeamId && !playerId;
    if (participatingTeamId) {
      this.playersInputs.forEach((input) => {
        const label = input.closest('label');
        label!.hidden = label!.dataset.parentId !== participatingTeamId;
      });
    }
  }

  setPreviousValues(): void {
    this.previousGameActionId = this.gameActionId;
    this.previousParticipatingTeamId = this.participatingTeamId;
    this.previousPlayerId = this.playerId;
  }

  async addClip() {
    let currentTime = this.player.currentTime();

    if (currentTime === undefined) {
      throw new Error('Current time is not available');
    }

    currentTime = Math.round(currentTime);

    const clip = {
      start_time: Math.max(currentTime - 30, 0),
      end_time: Math.min(currentTime + 30, this.videoDurationValue),
      game_action_id: this.gameActionId,
      player_id: this.playerId,
    };

    try {
      const res = await request<{ id: number }>(
        `/editor/videos/${this.videoIdValue}/clips`,
        {
          method: 'POST',
          body: { clip },
        },
        { json: true },
      );

      this.pageController.activeClipId = String(res.id);
      this.pageController.refreshClips();
      this.resetForm();
    } catch (error: unknown) {
      const e = error instanceof Error ? error : new Error(String(error));
      alert(`Failed to add clip: ${e.message}`);
    }
  }

  async updateClip() {
    const clip = {
      game_action_id: this.gameActionId,
      player_id: this.playerId,
    };

    try {
      await request(
        `/editor/videos/${this.videoIdValue}/clips/${this.id}`,
        {
          method: 'PATCH',
          body: { clip },
        },
        { json: true },
      );

      this.pageController.refreshClips();
    } catch (error: unknown) {
      const e = error instanceof Error ? error : new Error(String(error));
      alert(`Failed to update clip: ${e.message}`);
    }
  }

  resetForm(): void {
    this.element.replaceWith(PlayerCreateEditClipController.template.cloneNode(true));
  }

  editClip(opts: {
    id: string;
    gameActionId: string;
    participatingTeamId: string;
    playerId: string;
  }): void {
    this.mode = 'edit';
    this.id = opts.id;
    this.gameActionId = opts.gameActionId;
    this.participatingTeamId = opts.participatingTeamId;
    this.playerId = opts.playerId;
    this.setPreviousValues();
    this.render();
  }

  async deleteClip(): Promise<void> {
    if (!confirm('Are you sure you want to delete this clip?')) return;

    try {
      await request(`/editor/videos/${this.videoIdValue}/clips/${this.id}`, { method: 'DELETE' });

      this.pageController.refreshClips();
      this.resetForm();
    } catch (error: unknown) {
      const e = error instanceof Error ? error : new Error(String(error));
      alert(`Failed to delete clip: ${e.message}`);
    }
  }
}
